import * as React from "react";
import styled from "styled-components";

import { device } from "../../utils/media-queries";
import Seo from "../../layout/Seo";
import Layout from "../../layout/Layout";
import Topbar from "../../molecules/topbar";
import H2 from "../../atoms/h2";

import FondantBanner from "../../images/fondant-banner.svg";
import BlockchainIcon from "../../images/blockchain-icon.svg";
import FigmaIcon from "../../images/figma-icon.svg";
import JSIcon from "../../images/js-icon.svg";
import RustIcon from "../../images/rust-icon.svg";
import SmartIcon from "../../images/smart-icon.svg";
import PythonIcon from "../../images/python-icon.svg";
import DockerIcon from "../../images/docker-icon.svg";
import NodeIcon from "../../images/node-icon.svg";
import ReactIcon from "../../images/react-icon.svg";
import CloudIcon from "../../images/cloud-icon.svg";
import FondantImage1 from "../../images/fondant-img1.svg";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 80px 80px 80px;

  @media ${device.laptop} {
    padding: 0 40px 40px 40px;
  }

  @media ${device.mobile} {
    padding: 0 20px 20px 20px;
  }
`;

const ImageBanner = styled.img`
  width: 100%;
  max-width: 1240px;
  height: auto;
  margin-top: 192px;
  border-radius: 30px;

  @media ${device.tablet} {
    margin-top: 86px;
    border-radius: 15px;
  }

  @media ${device.mobile} {
    margin-top: 66px;
    border-radius: 15px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;

  @media ${device.tablet} {
    padding: 32px 0px 0px 0px;
  }

  @media ${device.mobile} {
    padding: 16px 0px 0px 0px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  margin-top: 32px;
  line-height: 24px;
`;

const TextMargin = styled(Text)`
  margin: 40px 0;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 112px 0;

  @media ${device.desktop} {
    margin: 80px 0;
  }

  @media ${device.laptop} {
    margin: 40px 0;
  }

  @media ${device.tablet} {
    flex-direction: column;
    margin: 40px 0;
  }

  @media ${device.mobile} {
    flex-direction: column;
    margin: 80px 0;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  align-self: center;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;

  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }

  @media ${device.mobile} {
    width: 40px;
    height: 40px;
  }
`;

const TechTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin: 92px 0 32px 0;
  color: ${({ theme }) => theme.colors.neutral.secondary};

  @media ${device.tablet} {
    margin: 40px 0 32px 0;
  }
`;

const TechImagesWrapper = styled(ImageWrapper)`
  gap: 24px;
  margin: 0 0 112px 0;

  @media ${device.laptop} {
    gap: 24px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    margin: 0 0 80px 0;
  }
`;

const Fondant = () => (
  <Layout>
    <Topbar isSolid />
    <PageWrapper>
      <ImageBanner src={FondantBanner} />
      <ContentWrapper>
        <H2>Fondant</H2>
        <TextMargin>
          This project will bring the integrated test environment to Casper
          Network. As we've already developed a couple of Casper dApps, an
          efficient test framework could significantly speed up the process. Our
          experiences with other blockchains, namely Ethereum, show clearly this
          product is a must in dApp/smart contract development. We want to
          deliver, open source, and maintain this product with the Casper
          community.
        </TextMargin>
        <Photo src={FondantImage1} alt="fondant-img" />
        <Text>
          As we are developing Casper blockchain apps, recently
          FriendlySoccer.io, we've noticed the need for an integrated testing
          environment. We need the Ganache equivalent in Casper Ecosystem:
          Quickly fire up a personal Casper network on your own machine. You can
          run tests, explore blocks, and inspect the state while controlling how
          the chain operates. Everything is wrapped up in a nice intuitive UI.
        </Text>
        <TechTitle>Technologies Used</TechTitle>
        <TechImagesWrapper>
          <Icon src={BlockchainIcon} />
          <Icon src={FigmaIcon} />
          <Icon src={JSIcon} />
          <Icon src={RustIcon} />
          <Icon src={SmartIcon} />
          <Icon src={PythonIcon} />
          <Icon src={DockerIcon} />
          <Icon src={NodeIcon} />
          <Icon src={ReactIcon} />
          <Icon src={CloudIcon} />
        </TechImagesWrapper>
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default Fondant;

export const Head = () => <Seo title="Blockbit.es | Fondant" />;
